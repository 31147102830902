html,body{
    background-color: $light;
    overflow-x: hidden;    
}

.font-weight-bold{
    font-weight: bold;
}

/* Line */
.line{
    border-left: 2px dotted #ccc;
    padding-top: 8px;
    padding-bottom: 6px;
    margin-left: 17px;    
}
/* End Line */

ol.breadcrumb{
    margin-bottom: 0;
}

.nav-tabs .nav-link.active, 
.nav-tabs .nav-item.show .nav-link{
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid #333;
}

/* Avatar */
/*
.avatar{
    width: 30px;
    height: 30px;
    background-color: $secondary;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    font-weight: bold;    
    color: white;
}
*/
/* End Avatar */

/* Badge */
/*
.badge{
    padding: 1em 1.3em;
    border-radius: 2rem;
}
*/
.badge{
    line-height: 15px;
}
/* End Badge */

.circle{
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #198754;
    border-radius: 50%;
    margin-right: 10px;
    top: -1px;
    position: relative;    
}